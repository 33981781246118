<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Add new'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-protocols' }">{{
            $t("MENU.ITEM.PROTOCOLS.PROTOCOLS")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.EDIT") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            class="mr-2"
            :defaultAction="'continue'"
            :isLoading="isLoadingSubcontractor"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
          <button
            v-if="!form.completed"
            class="btn btn-danger font-weight-bolder mr-2"
            @click="handleDelete"
          >
            {{ $t("BUTTON.DELETE") }}
          </button>
          <button
            v-if="!form.completed"
            class="btn btn-warning font-weight-bolder mr-2"
            @click="handlePreview"
          >
            {{ $t("BUTTON.PREVIEW") }}
          </button>
          <button
            v-if="form.completed"
            class="btn btn-warning font-weight-bolder mr-2"
            @click="handlePrint"
          >
            {{ $t("BUTTON.PRINT") }}
          </button>
          <button
            class="btn btn-secondary font-weight-bolder mr-2"
            @click="handleExcel"
          >
            {{ $t("BUTTON.EXCEL") }}
          </button>
          <button
            v-if="!form.completed"
            class="btn btn-primary font-weight-bolder"
            @click="handleFinalize"
          >
            {{ $t("BUTTON.FINALIZE") }}
          </button>
          <button
            v-if="!form.hasInvoice && form.completed"
            class="btn btn-primary font-weight-bolder"
            @click="handleAddInvoice"
          >
            {{ $t("BUTTON.ADD_INVOICE") }}
          </button>
        </div>
      </template>
      <template v-slot:body>
        <ProtocolForm
          v-if="!loading"
          ref="ProtocolForm"
          :form="form"
        ></ProtocolForm>
      </template>

      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            class="mr-2"
            :defaultAction="'continue'"
            :isLoading="isLoadingSubcontractor"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
          <button
            v-if="!form.completed"
            class="btn btn-danger font-weight-bolder mr-2"
            @click="handleDelete"
          >
            {{ $t("BUTTON.DELETE") }}
          </button>
          <button
            v-if="!form.completed"
            class="btn btn-warning font-weight-bolder mr-2"
            @click="handlePreview"
          >
            {{ $t("BUTTON.PREVIEW") }}
          </button>
          <button
            v-if="form.completed"
            class="btn btn-warning font-weight-bolder mr-2"
            @click="handlePrint"
          >
            {{ $t("BUTTON.PRINT") }}
          </button>
          <button
            class="btn btn-secondary font-weight-bolder mr-2"
            @click="handleExcel"
          >
            {{ $t("BUTTON.EXCEL") }}
          </button>
          <button
            v-if="!form.completed"
            class="btn btn-primary font-weight-bolder"
            @click="handleFinalize"
          >
            {{ $t("BUTTON.FINALIZE") }}
          </button>
          <button
            v-if="!form.hasInvoice && form.completed"
            class="btn btn-primary font-weight-bolder"
            @click="handleAddInvoice"
          >
            {{ $t("BUTTON.ADD_INVOICE") }}
          </button>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  UPDATE_PROTOCOL,
  FETCH_PROTOCOL,
  FINALIZE_PROTOCOL,
  GENERATE_PDF_URL,
  DELETE_PROTOCOL,
  GENERATE_EXCEL_URL,
} from "@/modules/protocols/store/protocol.module";

import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";
import ProtocolForm from "./ProtocolForm.vue";

import KTCard from "@/view/content/Card.vue";
import { mapState } from "vuex";

export default {
  name: "ProtocolEdit",
  components: {
    KTCard,
    SaveButtonDropdown,
    ProtocolForm,
  },
  data() {
    return {
      loading: true,
      errors: {},
      form: {
        assignorId: null,
        locationId: null,
        dateFrom: null,
        dateTo: null,
        invoiceNumber: null,
        invoiceDate: null,
        name: null,
        protocolDate: null,
        hasInvoice: null,
        billOfLadings: [],
        newBolwQtys: [],
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.PROTOCOLS.PROTOCOLS"),
        route: { name: "list-protocols" },
      },
      { title: this.$i18n.t("LABELS.EDIT") },
    ]);

    this.$store.dispatch(FETCH_PROTOCOL, this.$route.params.id).then((data) => {
      this.form = data.data;
      if (this.form.completed && !this.form.invoiceNumber) {
        this.form.invoiceDate = new Date().toISOString().slice(0, 10);
      }
      this.loading = false;
    });
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
  methods: {
    onSave(next) {
      let vm = this;

      this.$refs.ProtocolForm.$v.form.$touch();
      if (this.$refs.ProtocolForm.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly",
        });
        return;
      }
      this.$store
        .dispatch(UPDATE_PROTOCOL, {
          id: this.$route.params.id,
          payload: this.form,
        })
        .then((data) => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message,
          });
          // let itemEdit = data.data;
          if (next == "continue") {
          }
          if (next == "new") {
            //New
            this.$router.push({ name: "add-protocols" });
          }
          if (next == "exit") {
            this.$router.push({ name: "list-protocols" });
          }
        })
        .catch((response) => {
          this.errors = this.$errors.format(response.data.errors);
          vm.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message,
          });
        });
    },
    handlePreview() {
      this.$store.dispatch(GENERATE_PDF_URL, this.form.id);
    },
    handlePrint() {
      this.$store.dispatch(GENERATE_PDF_URL, this.form.id);
    },
    handleExcel() {
      this.$store.dispatch(GENERATE_EXCEL_URL, this.form.id);
    },
    handleFinalize() {
      this.$dialog
        .confirm(this.$t("BUTTON.PLEASE_CONFIRM"))
        .then(() => {
          this.$store
            .dispatch(FINALIZE_PROTOCOL, this.$route.params.id)
            .then((data) => {
              this.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message,
              });
              this.form.completed = 1;
            });
        })
        .catch(() => {});
    },
    handleAddInvoice() {
      this.$refs.ProtocolForm.$v.form.$touch();
      if (this.$refs.ProtocolForm.$v.form.$anyError) {
        return;
      }
      if (!this.form.invoiceNumber || !this.form.invoiceDate) {
        this.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Моля въведете номер и дата на фактура!",
        });
        return;
      }
      this.$dialog.confirm(this.$t("BUTTON.PLEASE_CONFIRM")).then(() => {
        this.form.hasInvoice = 1;
        this.onSave("exit");
      });
    },
    handleDelete() {
      this.$dialog
        .confirm(this.$t("BUTTON.PLEASE_CONFIRM"))
        .then(() => {
          this.$store
            .dispatch(DELETE_PROTOCOL, this.$route.params.id)
            .then((data) => {
              this.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message,
              });
              this.$router.push({ name: "list-protocols" });
            });
        })
        .catch(() => {});
    },
  },
};
</script>

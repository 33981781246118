<template>
  <v-card class="mt-5">
    <v-card-title>
      Избор на товарителници
      <v-spacer></v-spacer>
      <FormInput
        style="flex: 1"
        type="text"
        :i18n="{
          label: 'LABELS.SEARCH',
          placeholder: $t('LABELS.SEARCH'),
        }"
        v-model="search"
        button
      >
      </FormInput>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :search="search"
      :expanded="expanded"
      sort-by="bolDate"
      v-model="selectedBols"
      item-key="id"
      show-select
      hide-default-footer
      disable-pagination
      show-expand
      :custom-filter="customFilter"
      @item-selected="handleItemSelected"
    >
      <template v-slot:[`item.bolDate`]="{ item }">
        {{ item.bolDate | moment("DD.MM.YYYY") }}
      </template>

      <template v-slot:[`item.servicePriceTotal`]="{ item }">
        {{ calcTotalWork(item) }}
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <table class="work-table my-2">
            <thead>
              <tr>
                <th style="width: 15%;">
                  {{ $t("FORM_LABELS.WORK_DATE") }}
                </th>
                <th style="width: 30%;">
                  {{ $t("FORM_LABELS.SERVICE") }}
                </th>
                <th style="width: 10%;">
                  {{ $t("FORM_LABELS.QTY") }}
                </th>
                <th style="width: 15%;">
                  {{ $t("FORM_LABELS.MEASURE_NAME") }}
                </th>
                <th style="width: 15%;">
                  {{ $t("FORM_LABELS.PRICE") }}
                </th>
                <th style="width: 15%;">
                  {{ $t("FORM_LABELS.TOTAL") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="w in item.work" :key="w.id">
                <td>
                  {{ w.dateWorked | moment("DD.MM.YYYY") }}
                </td>
                <td>
                  {{ w.serviceName }}
                </td>
                <td>
                  <FormInput
                    type="number"
                    @input="handleQtyInput(w.id, $event)"
                    :value="w.serviceQty"
                  ></FormInput>
                </td>
                <td>{{ w.measureName }}</td>
                <td>
                  <FormInput
                    type="number"
                    @input="handlePriceInput(item.id, w.id, $event)"
                    :value="w.servicePrice"
                  ></FormInput>
                </td>
                <td>
                  {{ (w.servicePrice * w.serviceQty).toFixed(2) }}
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </template>
    </v-data-table>
    <div class="text-right p-5">
      <h5>
        Обща сума: <b>{{ totalSum }} лв.</b>
      </h5>
    </div>
  </v-card>
</template>

<script>
import FormInput from "@/view/content/forms/components/FormInput.vue";

export default {
  name: "BillOfLadingTable",
  components: {
    FormInput,
  },
  props: {
    items: Array,
    selectedBols: Array,
    loading: Boolean,
  },
  data() {
    return {
      activeFilters: {},
      search: "",
      expanded: [],
      totalSum: 0,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Товарителница",
          sortable: true,
          value: "billNumber",
        },
        {
          text: "Дата",
          value: "bolDate",
          sortable: true,
        },

        {
          text: this.$t("FORM_PLACEHOLDERS.MACHINE_REGISTRATION_PLATE"),
          value: "machineInfo.registrationPlate",
          sortable: false,
        },
        {
          text: "Общо",
          value: "servicePriceTotal",
          sortable: false,
        },
      ];
    },
  },
  mounted() {
    this.selectedBols.forEach((sb) => {
      this.expanded.push(sb);
    });
  },
  watch: {
    selectedBols: {
      immediate: true,
      handler(value) {
        this.$emit("select", value);
        this.calcTotalSum();
      },
    },
  },
  methods: {
    customFilter(value, search, item) {
      return (
        value != null &&
        search != null &&
        (value.toLowerCase().toString().indexOf(search.toLowerCase()) !== -1 ||
          item.work
            .map((w) => w.serviceName)
            .join()
            .toLowerCase()
            .indexOf(search.toLowerCase()) !== -1)
      );
    },
    handlePriceInput(id, wid, value) {
      this.$emit("price-input", id, wid, value);
      this.calcTotalSum();
    },
    handleQtyInput(id, value) {
      this.$emit("qty-input", id, value);
    },
    handleItemSelected(obj) {
      if (obj.value) {
        this.expanded.push(obj.item);
      } else {
        const index = this.expanded.findIndex((e) => e.id == obj.id);
        if (index) {
          this.expanded.splice(index, 1);
        }
      }
    },
    calcTotalWork(item) {
      let sum = 0;
      item.work.forEach((i) => {
        sum += i.servicePrice * i.serviceQty;
      });
      return sum.toFixed(2);
    },
    calcTotalSum() {
      let sum = 0;
      this.selectedBols.forEach((sb) => {
        const workArray = sb.work;
        let sumWork = 0;
        workArray.forEach((bolw) => {
          sumWork += bolw.serviceQty * bolw.servicePrice;
        });
        sum += sumWork;
      });
      this.totalSum = sum.toFixed(2);
    },
  },
};
</script>

<style scoped>
.work-table {
  width: 100%;
}
.work-table td,
.work-table th {
  padding: 0 15px;
}
</style>

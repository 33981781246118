var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_vm._v(" Избор на товарителници "),_c('v-spacer'),_c('FormInput',{staticStyle:{"flex":"1"},attrs:{"type":"text","i18n":{
        label: 'LABELS.SEARCH',
        placeholder: _vm.$t('LABELS.SEARCH'),
      },"button":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"search":_vm.search,"expanded":_vm.expanded,"sort-by":"bolDate","item-key":"id","show-select":"","hide-default-footer":"","disable-pagination":"","show-expand":"","custom-filter":_vm.customFilter},on:{"item-selected":_vm.handleItemSelected},scopedSlots:_vm._u([{key:"item.bolDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.bolDate,"DD.MM.YYYY"))+" ")]}},{key:"item.servicePriceTotal",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.calcTotalWork(item))+" ")]}},{key:"expanded-item",fn:function(ref){
      var headers = ref.headers;
      var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('table',{staticClass:"work-table my-2"},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"15%"}},[_vm._v(" "+_vm._s(_vm.$t("FORM_LABELS.WORK_DATE"))+" ")]),_c('th',{staticStyle:{"width":"30%"}},[_vm._v(" "+_vm._s(_vm.$t("FORM_LABELS.SERVICE"))+" ")]),_c('th',{staticStyle:{"width":"10%"}},[_vm._v(" "+_vm._s(_vm.$t("FORM_LABELS.QTY"))+" ")]),_c('th',{staticStyle:{"width":"15%"}},[_vm._v(" "+_vm._s(_vm.$t("FORM_LABELS.MEASURE_NAME"))+" ")]),_c('th',{staticStyle:{"width":"15%"}},[_vm._v(" "+_vm._s(_vm.$t("FORM_LABELS.PRICE"))+" ")]),_c('th',{staticStyle:{"width":"15%"}},[_vm._v(" "+_vm._s(_vm.$t("FORM_LABELS.TOTAL"))+" ")])])]),_c('tbody',_vm._l((item.work),function(w){return _c('tr',{key:w.id},[_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(w.dateWorked,"DD.MM.YYYY"))+" ")]),_c('td',[_vm._v(" "+_vm._s(w.serviceName)+" ")]),_c('td',[_c('FormInput',{attrs:{"type":"number","value":w.serviceQty},on:{"input":function($event){return _vm.handleQtyInput(w.id, $event)}}})],1),_c('td',[_vm._v(_vm._s(w.measureName))]),_c('td',[_c('FormInput',{attrs:{"type":"number","value":w.servicePrice},on:{"input":function($event){return _vm.handlePriceInput(item.id, w.id, $event)}}})],1),_c('td',[_vm._v(" "+_vm._s((w.servicePrice * w.serviceQty).toFixed(2))+" ")])])}),0)])])]}}],null,true),model:{value:(_vm.selectedBols),callback:function ($$v) {_vm.selectedBols=$$v},expression:"selectedBols"}}),_c('div',{staticClass:"text-right p-5"},[_c('h5',[_vm._v(" Обща сума: "),_c('b',[_vm._v(_vm._s(_vm.totalSum)+" лв.")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
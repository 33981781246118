<template>
  <b-row>
    <div class="col-md-12">
      <b-modal ref="modal" id="modalscoped" scrollable size="lg">
        <div class="col-lg-12">
          <label class="typo__label">Списък услуги</label>
          <multiselect
            v-model="services"
            :options="options"
            :blockKeys="['Delete']"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Изберете от списъка услуги"
            label="name"
            track-by="name"
            :preselect-first="false"
          >
            <template
              class="col-md-12"
              slot="selection"
              slot-scope="{ values, isOpen }"
              ><span
                class="multiselect__single"
                v-if="values.length &amp;&amp; !isOpen"
                >{{ values.length }} избрани услуги</span
              ></template
            >
          </multiselect>
        </div>

        <div v-if="services">
          <div class="row p-4" v-for="item in services" :key="item.id">
            <div class="col-8 pt-0 pb-0 mb-0 mt-0">
              <b-form-input
                v-model="item.name"
                placeholder="Услуга"
                disabled
              ></b-form-input>
            </div>
            <div class="col-2 pt-0 pb-0 mb-0 mt-0">
              <FormInput
                disabled
                type="text"
                :i18n="{ label: '', placeholder: 'Мярка' }"
                v-model="item.measureName"
              ></FormInput>
            </div>
            <div class="col-2 pt-0 pb-0 mb-0 mt-0 ">
              <FormInput
                type="number"
                :i18n="{ label: '', placeholder: 'Цена' }"
                v-model="item.price"
              ></FormInput>
            </div>
          </div>
        </div>

        <template #modal-footer>
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button
            v-if="withUpdate"
            size="md"
            variant="primary"
            @click="saveServices(true)"
          >
            Запиши и обнови цени
          </b-button>
          <b-button size="md" variant="success" @click="saveServices(false)">
            Запиши
          </b-button>
          <b-button size="md" variant="danger" @click="closeModal">
            Затвори
          </b-button>
        </template>
      </b-modal>
    </div>
  </b-row>
</template>

<script>
import { UPDATE_LOCATION_SERVICES } from "@/modules/assignorslocation/store/location-service.module";
import { FETCH_LOCATION_SERVICES } from "@/modules/assignorslocation/store/location-service.module";
import { FETCH_SERVICES } from "@/modules/services/store/service.module";
import Multiselect from "vue-multiselect";
import FormInput from "@/view/content/forms/components/FormInput.vue";

export default {
  name: "ServicesModal",
  components: {
    Multiselect,
    FormInput,
  },
  props: {
    locationId: Number,
    withUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      services: [],
      options: [],
    };
  },
  methods: {
    show() {
      this.$store.dispatch(FETCH_SERVICES).then((data) => {
        this.options = data.data.items;
        this.$store
          .dispatch(FETCH_LOCATION_SERVICES, this.locationId)
          .then((data) => {
            this.services = data.data;
          });
      });
      this.$refs["modal"].show();
    },
    closeModal() {
      this.$refs["modal"].hide();
    },
    saveServices(withUpdate = false) {
      let vm = this;
      const serviceList = { services: this.services };

      this.$store
        .dispatch(UPDATE_LOCATION_SERVICES, {
          id: this.locationId,
          payload: serviceList,
        })
        .then((data) => {
          this.$refs["modal"].hide();
          vm.errors = data.data.errors;

          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message,
          });
          this.$emit("updated");
          if (withUpdate === true) {
            this.$emit("updated:update", this.services);
          }
        })
        .catch((response) => {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message,
          });
        });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style type="text/css">
.modal-dialog {
  min-height: 500px !important;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.form.completed)?_c('b-row',[_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"id":"item-vehicle-group","label":_vm.$t('FORM_LABELS.INVOICE_NUMBER'),"label-for":"item-reg-plate-input"}},[_c('FormInput',{attrs:{"id":"item-invoice-number","type":"text","name":"invoice-number","i18n":{
            label: 'FORM_LABELS.INVOICE_NUMBER',
            placeholder: 'FORM_LABELS.INVOICE_NUMBER',
          }},model:{value:(_vm.form.invoiceNumber),callback:function ($$v) {_vm.$set(_vm.form, "invoiceNumber", $$v)},expression:"form.invoiceNumber"}})],1)],1),_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"id":"item-invoice-date-group","label":_vm.$t('FORM_LABELS.INVOICE_DATE'),"label-for":"item-reg-plate-input"}},[_c('FormDatepicker',{attrs:{"id":"item-invoice-date","type":"text","name":"invoice_date","i18n":{
            placeholder: 'FORM_LABELS.INVOICE_DATE',
          },"max":_vm.today},model:{value:(_vm.form.invoiceDate),callback:function ($$v) {_vm.$set(_vm.form, "invoiceDate", $$v)},expression:"form.invoiceDate"}})],1)],1)],1):_vm._e(),_c('v-card',{attrs:{"flat":""}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('b-form',[_c('FormBuilder',{attrs:{"form":_vm.form,"schemaJson":_vm.schemaJson,"v":_vm.$v}})],1)],1),(_vm.canShowBillsOfLading)?_c('div',{staticClass:"text-right mt-n10 col-md-12"},[(_vm.form.locationId)?_c('button',{staticClass:"btn btn-info mr-2",on:{"click":_vm.openModal}},[_vm._v(" Услуги "+_vm._s(_vm.selectedLocationName)+" ")]):_vm._e(),_c('button',{staticClass:"btn btn-success",on:{"click":_vm.handleShowBols}},[_vm._v(" "+_vm._s(_vm.$t("LABELS.SHOW_BILLS_OF_LADING"))+" ")])]):_vm._e()]),_c('v-overlay',{attrs:{"value":_vm.isLoadingSubcontractor}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),(_vm.bolResults.length > 0)?_c('b-row',[_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"id":"item-vehicle-group","label":_vm.$t('FORM_LABELS.PROTOCOL_NAME'),"label-for":"item-reg-plate-input"}},[_c('FormInput',{attrs:{"id":"item-protocol-name","type":"number","name":"protocol_number","i18n":{
              label: 'FORM_PLACEHOLDERS.PROTOCOL_NAME',
              placeholder: 'FORM_PLACEHOLDERS.PROTOCOL_NAME',
            },"validateState":_vm.validateState('name')},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1),_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"id":"item-vehicle-group","label":_vm.$t('FORM_LABELS.DATE'),"label-for":"item-reg-plate-input"}},[_c('FormDatepicker',{attrs:{"id":"item-protocol-date","type":"text","name":"protocol_date","i18n":{
              placeholder: 'FORM_LABELS.DATE',
            },"validations":'validations: {required: false, minLength: 0}',"validateState":_vm.validateState('protocolDate'),"max":_vm.today},model:{value:(_vm.form.protocolDate),callback:function ($$v) {_vm.$set(_vm.form, "protocolDate", $$v)},expression:"form.protocolDate"}})],1)],1),_c('b-col',{attrs:{"md":12}},[_c('b-label',{staticClass:"ml-0 pl-0"},[_vm._v(" "+_vm._s(_vm.$t("FORM_LABELS.SHOW_UNLOADING"))+" "),_c('FormSwitch',{attrs:{"id":"show_unloading","type":"checkbox","name":"show_unloading"},model:{value:(_vm.form.showUnloading),callback:function ($$v) {_vm.$set(_vm.form, "showUnloading", $$v)},expression:"form.showUnloading"}})],1)],1)],1):_vm._e(),(_vm.bolResults.length > 0 || _vm.bolLoading)?_c('BillOfLadingTable',{ref:"BillOfLadingTable",attrs:{"items":_vm.bolResults,"selected-bols":_vm.form.billOfLadings,"loading":_vm.bolLoading},on:{"select":_vm.handleTableSelect,"price-input":_vm.handlePriceInput,"qty-input":_vm.handleQtyInput}}):_vm._e(),_c('ServicesModal',{ref:"ServicesModal",attrs:{"with-update":"","location-id":_vm.form.locationId},on:{"updated:update":_vm.handleServicesUpdated}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
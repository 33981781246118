<template>
  <div>
    <b-row v-if="form.completed">
      <b-col :md="6">
        <b-form-group
          id="item-vehicle-group"
          :label="$t('FORM_LABELS.INVOICE_NUMBER')"
          label-for="item-reg-plate-input"
        >
          <FormInput
            id="item-invoice-number"
            type="text"
            name="invoice-number"
            :i18n="{
              label: 'FORM_LABELS.INVOICE_NUMBER',
              placeholder: 'FORM_LABELS.INVOICE_NUMBER',
            }"
            v-model="form.invoiceNumber"
          ></FormInput>
        </b-form-group>
      </b-col>
      <b-col :md="6">
        <b-form-group
          id="item-invoice-date-group"
          :label="$t('FORM_LABELS.INVOICE_DATE')"
          label-for="item-reg-plate-input"
        >
          <FormDatepicker
            id="item-invoice-date"
            type="text"
            name="invoice_date"
            :i18n="{
              placeholder: 'FORM_LABELS.INVOICE_DATE',
            }"
            :max="today"
            v-model="form.invoiceDate"
          ></FormDatepicker>
        </b-form-group>
      </b-col>
    </b-row>
    <v-card flat>
      <div class="row">
        <div class="col-md-12">
          <b-form>
            <FormBuilder
              :form="form"
              :schemaJson="schemaJson"
              :v="$v"
            ></FormBuilder>
          </b-form>
        </div>

        <div v-if="canShowBillsOfLading" class="text-right mt-n10 col-md-12">
          <button
            v-if="form.locationId"
            class="btn btn-info mr-2"
            @click="openModal"
          >
            Услуги {{ selectedLocationName }}
          </button>
          <button class="btn btn-success" @click="handleShowBols">
            {{ $t("LABELS.SHOW_BILLS_OF_LADING") }}
          </button>
        </div>
      </div>
      <v-overlay :value="isLoadingSubcontractor">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <b-row v-if="bolResults.length > 0">
        <b-col :md="6">
          <b-form-group
            id="item-vehicle-group"
            :label="$t('FORM_LABELS.PROTOCOL_NAME')"
            label-for="item-reg-plate-input"
          >
            <FormInput
              id="item-protocol-name"
              type="number"
              name="protocol_number"
              :i18n="{
                label: 'FORM_PLACEHOLDERS.PROTOCOL_NAME',
                placeholder: 'FORM_PLACEHOLDERS.PROTOCOL_NAME',
              }"
              :validateState="validateState('name')"
              v-model="form.name"
            ></FormInput>
          </b-form-group>
        </b-col>
        <b-col :md="6">
          <b-form-group
            id="item-vehicle-group"
            :label="$t('FORM_LABELS.DATE')"
            label-for="item-reg-plate-input"
          >
            <FormDatepicker
              id="item-protocol-date"
              type="text"
              name="protocol_date"
              :i18n="{
                placeholder: 'FORM_LABELS.DATE',
              }"
              :validations="'validations: {required: false, minLength: 0}'"
              :validateState="validateState('protocolDate')"
              :max="today"
              v-model="form.protocolDate"
            ></FormDatepicker>
          </b-form-group>
        </b-col>
        <b-col :md="12">
          <b-label class="ml-0 pl-0">
            {{ $t("FORM_LABELS.SHOW_UNLOADING") }}
            <FormSwitch
              id="show_unloading"
              type="checkbox"
              name="show_unloading"
              v-model="form.showUnloading"
            ></FormSwitch>
          </b-label>
        </b-col>
      </b-row>
      <BillOfLadingTable
        ref="BillOfLadingTable"
        v-if="bolResults.length > 0 || bolLoading"
        :items="bolResults"
        :selected-bols="form.billOfLadings"
        :loading="bolLoading"
        @select="handleTableSelect"
        @price-input="handlePriceInput"
        @qty-input="handleQtyInput"
      ></BillOfLadingTable>

      <ServicesModal
        ref="ServicesModal"
        with-update
        :location-id="form.locationId"
        @updated:update="handleServicesUpdated"
      ></ServicesModal>
    </v-card>
  </div>
</template>

<script>
import FormBuilder from "@/view/content/forms/FormBuilder.vue";
import FormInput from "@/view/content/forms/components/FormInput.vue";
import FormSwitch from "@/view/content/forms/components/FormSwitch.vue";
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";
import BillOfLadingTable from "./BillOfLadingTable.vue";
import ServicesModal from "@/modules/bill-of-lading/pages/ServicesModal.vue";

import { validationMixin } from "vuelidate";
import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

import { mapGetters } from "vuex";
import moment from "moment";

import { SEARCH_BILL_OF_LADING_PROTOCOLS } from "@/modules/bill-of-lading/store/bill-of-lading.module";
import { FETCH_ASSIGNORS } from "@/modules/assignors/store/assignors.module";
import { FETCH_ASSIGNOR_LOCATIONS } from "@/modules/assignorslocation/store/location.module";
import { FETCH_PROTOCOLS } from "@/modules/protocols/store/protocol.module";

export default {
  name: "ProtocolForm",
  mixins: [validationMixin, formBuilderMixin],
  components: {
    FormBuilder,
    FormInput,
    FormDatepicker,
    BillOfLadingTable,
    ServicesModal,
    FormSwitch,
  },
  props: {
    form: Object,
  },
  validations() {
    let vm = this;

    let tmpValidationObject = this.generateFormValidationsOutOfSchemaJson(
      this.schemaJson
    );

    const nameValidations = {
      required: true,
    };
    const dateValidations = {
      required: true,
      minLength: 0,
    };

    tmpValidationObject.form["name"] = vm.setItemValidations(nameValidations);
    tmpValidationObject.form["protocolDate"] = vm.setItemValidations(
      dateValidations
    );

    return tmpValidationObject;
  },
  data() {
    return {
      today: new Date().toISOString().slice(0, 10),
      bolLoading: false,
      assignors: [],
      locations: [],
      bolResults: [],
      newBolwQtys: {},
    };
  },
  computed: {
    schemaJson() {
      return {
        fields: [
          {
            type: "select",
            inputType: "select",
            groupId: "item-unit-group",
            id: "item-location-input",
            feedback_id: "assignorId",
            colSm: "3",
            divider: false,
            clearable: false,
            i18n: {
              label: "MENU.ITEM.ASL.ASSIGNOR",
              placeholder: "MENU.ITEM.ASL.ASSIGNOR",
            },
            options: this.assignors,
            validations: {
              required: true,
            },
            model: "assignorId",
          },
          {
            type: "select",
            inputType: "select",
            groupId: "item-unit-group",
            id: "item-location-input",
            feedback_id: "locationId",
            colSm: "3",
            divider: false,
            clearable: false,
            i18n: {
              label: "MENU.ITEM.ASL.LOCATION",
              placeholder: "MENU.ITEM.ASL.LOCATION",
            },
            options: this.locations,
            validations: {
              required: true,
            },
            model: "locationId",
          },
          {
            type: "datepicker",
            inputType: "text",
            id: "from-date",
            groupId: "from-date-group",
            required: "required",
            feedback_id: "from-date",
            divider: false,
            colSm: "3",
            i18n: {
              label: "FORM_LABELS.FROM_DATE",
              placeholder: "FORM_LABELS.FROM_DATE",
              validation: "FORM_LABELS.FROM_DATE",
            },
            validations: {
              required: true,
              minLength: 2,
            },
            translatable: false,
            max: this.today,
            model: "dateFrom",
          },
          {
            type: "datepicker",
            inputType: "text",
            id: "to-date",
            groupId: "to-date-group",
            required: "required",
            feedback_id: "to-date",
            divider: false,
            colSm: "3",
            i18n: {
              label: "FORM_LABELS.TO_DATE",
              placeholder: "FORM_LABELS.TO_DATE",
              validation: "FORM_LABELS.TO_DATE",
            },
            validations: {
              required: true,
              minLength: 1,
            },
            translatable: false,
            max: this.today,
            model: "dateTo",
          },
        ],
      };
    },
    canShowBillsOfLading() {
      return (
        this.form.assignorId &&
        this.form.locationId &&
        this.form.dateFrom &&
        this.form.dateTo
      );
    },
    selectedLocationName() {
      const loc = this.locations.find((s) => s.id === this.form.locationId);
      return loc ? loc.name : null;
    },
    ...mapGetters(["isLoadingSubcontractor"]),
  },
  mounted() {
    this.$store
      .dispatch(
        FETCH_ASSIGNORS,
        this.$url.transformParams({
          onlySelectValues: true,
          selectValueKey: "company_name",
        })
      )
      .then((data) => {
        this.assignors = data.data.items;
        this.fetchLocations();
      });
    if (this.form.assignorId && this.form.locationId) {
      this.handleShowBols();
    }
  },
  watch: {
    "form.assignorId": {
      handler() {
        this.fetchLocations();
        this.resetBols();
      },
    },
    "form.locationId": {
      handler() {
        this.resetBols();
      },
    },
    "form.dateFrom": {
      handler() {
        this.resetBols();
      },
    },
    "form.dateTo": {
      handler() {
        this.resetBols();
      },
    },
  },
  methods: {
    searchBOL() {
      this.bolLoading = true;

      let params = {
        assignor_id: this.form.assignorId,
        location_id: this.form.locationId,
        date_from: this.form.dateFrom,
        date_to: this.form.dateTo,
      };

      if (this.form.id) {
        params.protocol_id = this.form.id;
      }

      let vm = this;

      vm.$store
        .dispatch(
          SEARCH_BILL_OF_LADING_PROTOCOLS,
          this.$url.transformParams(params)
        )
        .then((data) => {
          if (data && data.data.length > 0) {
            this.bolResults = data.data;
            if (!this.form.protocolDate) {
              this.form.protocolDate = moment().format("YYYY-MM-DD");
            }
          } else {
            this.bolResults = [];
            vm.$notify({
              group: "notify",
              type: "error",
              title: "<i class='flaticon-close'></i> Warning",
              text: this.$t("ALERTS.NO_DATA"),
            });
          }
          this.bolLoading = false;
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    handleTableSelect(items) {
      this.form.billOfLadings = items;
    },
    fetchLocations() {
      if (this.form.assignorId) {
        const id = this.form.assignorId;

        this.$store
          .dispatch(
            FETCH_ASSIGNOR_LOCATIONS,
            this.$url.transformParams({
              assignorId: id,
            })
          )
          .then((data) => {
            this.locations = data.data.items;
            this.form.locationId = this.locations.find(
              (l) => l.id === this.form.locationId
            )
              ? this.form.locationId
              : null;
          });
      }
    },
    handlePriceInput(id, wid, value) {
      const bol = this.bolResults.find((b) => b.id === id);
      let bolw = bol.work.find((bw) => bw.id === wid);
      bolw.servicePrice = value;
      const bolForm = this.form.billOfLadings.find((b) => b.id === id);
      let bolFormW = bolForm ? bolForm.work.find((bw) => bw.id === wid) : null;
      if (bolFormW) {
        bolFormW.servicePrice = value;
      }
    },
    handleQtyInput(id, value) {
      if (!this.form.newBolwQtys) {
        this.$set(this.form, "newBolwQtys", {});
      }
      this.form.newBolwQtys[id] = value;
    },
    openModal() {
      this.$refs.ServicesModal.show();
    },
    handleServicesUpdated(services) {
      this.bolResults.forEach((bol) => {
        bol.work.forEach((bolw) => {
          const service = services.find((s) => s.serviceId === bolw.serviceId);
          if (service) {
            bolw.servicePrice = service.price;
          }
        });
      });
      this.form.billOfLadings.forEach((bol) => {
        bol.work.forEach((bolw) => {
          const service = services.find((s) => s.serviceId === bolw.serviceId);
          if (service) {
            bolw.servicePrice = service.price;
          }
        });
      });
      this.$refs.BillOfLadingTable.calcTotalSum();
    },
    findLastProtocolNumber() {
      this.$store
        .dispatch(
          FETCH_PROTOCOLS,
          this.$url.transformParams({
            assignorId: this.form.assignorId,
            locationId: this.form.locationId,
            itemsPerPage: 1,
            sortBy: "name",
            sortDesc: true,
          })
        )
        .then((data) => {
          if (data.data.items && data.data.items.length > 0) {
            this.form.name = data.data.items[0].name + 1;
          }
        });
    },
    handleShowBols() {
      if (!this.form.id) {
        this.findLastProtocolNumber();
      }

      this.searchBOL();
    },
    resetBols() {
      this.bolResults = [];
      this.form.billOfLadings = [];
    },
  },
};
</script>

<style type="text/css">
.modal-dialog {
  min-height: 500px !important;
}
.sort_div_holder {
  position: relative;
}
.sort_div {
  display: inline-block;
  padding: 16px 10px;
}
.sort_div_filter {
  position: absolute;
  top: 8px;
  right: -34px;
}

.v-list--dense .v-list-item,
.v-list-item--dense {
  min-height: 20px !important;
  height: 2rem;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 0.5rem !important;
}

.v-list-item--link {
  transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.v-list-item--link:hover {
  background-color: rgba(0, 0, 0, 0.13);
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 13px !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > th.sortable {
  position: relative;
}
.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
}
.v-data-table-header__icon {
  height: 1px !important;
  float: right !important;
  top: -27px !important;
  right: -3px;
}
</style>
